import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/navbar';
import TopBar from './components/TopBar/topbar';
import Navigation from './Navigation/Navigation';
import Footer from './components/Footer/footer';
import AboutFormation from './pages/AboutFormation/aboutFormation';
import FixedButton from './components/FixedButton/fixedButton';
import Admission from './pages/Admission/admission';
import CandidatureProcess from './pages/Admission/candidatureProcess';
import './App.css';

function App() {
  return (
    <Router>
      <TopBar />
      <Navbar />
        <div>
          <Routes>
            <Route path="/" element={<Navigation />} />
            <Route path="/formation/:id" element={<AboutFormation />} />
            <Route path="/admission" element={<Admission />} />

          </Routes>
        </div>
        <Footer />
        <FixedButton />

    </Router>
  );
}

export default App;
