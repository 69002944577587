import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import './fixedButton.css'; // Importez votre fichier de style CSS pour le bouton fixe
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FixedButton = () => {
  const { t } = useTranslation();
  return (
    <div className="floatingButtonContainer">
      <Link to="/admission#candidature" style={{ textDecoration: 'none', color: 'white' }}>
        <Button
          variant="contained"
          className="floatingButton"
          startIcon={<AddIcon />}
        >
          {t('admission')}
        </Button>
      </Link>
    </div>
  );
};

export default FixedButton;
