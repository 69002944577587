// src/pages/Home.js
import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './home.css'; // Importez votre fichier de style CSS pour la page Hom
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="background">
      <div className="text_intro">
        <Typography className="body"> {t('intro')}</Typography>
      </div>
      
      <div className="card">
        <div className="content">
          <Typography className="body1" variant="body1"> {t('intro')}</Typography>
          <Typography className='h4' variant="h4">{t('invitation')}</Typography>
          
          <Button variant="contained" color="primary" >    <Link to="/admission" style={{ textDecoration: 'none' }}>
          {t('admission')} </Link>
        </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
