import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './finance.css'; // Importez votre fichier de style CSS pour le composant Finance
import { useTranslation } from 'react-i18next';


const createData = (col1, col2, col3) => {


  return { col1, col2, col3 };
};


const Finance = () => {
  const { t } = useTranslation();

  const rows = [
    createData(t('test_admission'), '20 000 Ar'),
    createData(t('registration'), '100 000 Ar'),
    createData(t('prep_class_english'), '200 000 Ar'),
    createData(t('prep_class_hospitality'), '200 000 Ar'),
    createData(t('job_class'), '400 000 Ar'),
    createData(t('professional_internship'), '600 000 Ar'),
    createData(t('insurance_uniform'), '95 000 Ar')
];


  return (
    <div className="finance-container">
            <Typography variant="h4" className="finance-title">
            {t('price_title')}
            </Typography>
    <div className="finance-cards-container">
      <div className="finance-card">
  
        <Card>
          <CardContent>
           
            <TableContainer component={Paper} className="finance-table-container">
              <Table aria-label="finance table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header"></TableCell>
                    <TableCell className="table-header">{t('prix')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.col1}</TableCell>
                      <TableCell>{row.col2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
      <div className="finance-card1">
        <Card>
          <CardContent>
            <Typography variant="body1" className="reduction-text">
            {t('reduction_title')}
              <br /><br />
              <span className="highlighted-text">
                <span className="bold-text">{t('reduction_bold1')}:</span> 8h - 16h
              </span>
              <br /><br />
              <span className="highlighted-text">
                <span className="bold-text">{t('reduction_bold2')} :</span> 06h15 - 21h
              </span>
              <br /><br />
              <span className="formation-regimes">{t('reduction_subtitle')}</span>
              <br /><br />
              <span className="formation-option">
                <span className="bold-text">1. {t('reduction_bold1')}:</span> {t('reduction_text1')} </span>
              <br /><br/>  
              <span className="formation-option">
                <span className="bold-text">2. {t('reduction_bold2')}:</span> {t('reduction_text2')} </span>
            </Typography>
          </CardContent>
        </Card>
      </div>
      </div>
      
    </div>
  );
};

export default Finance;
