// About.js
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import noelisoaImage from '../../static/images/noelisoa1.jpeg';
import './about.css';
import { useTranslation } from 'react-i18next';


const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <Typography variant="h4" className="about-title">My Future Madagascar</Typography>
      <Grid container spacing={2}>
        {/* Partie texte à gauche */}
        <Grid item xs={12} md={6}>
          <div className="about-text-container">
            <Typography variant="body1" className="about-text" style={{fontSize: "100%"}}>  {t('description1')}<br/><br/>{t('description2')}
              </Typography>
          </div>
        </Grid>
        {/* Partie image dans un card à droite */}
        <Grid item xs={12} md={6}>
          <Card className="about-card">
            <CardMedia
              component="img"
              image={noelisoaImage}
              alt="Image"
              className="about-card-media"
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
