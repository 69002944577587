import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/home';
import About from '../pages/About/about';
import Formation from '../pages/Formation/formation';
import Finance from '../pages/Finance/finance';
import TopBar from '../components/TopBar/topbar';
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/footer';
import FixedButton from '../components/FixedButton/fixedButton';
import Testimonials from '../pages/Temoignage/temoignage';

function Navigation() {
    return (
      <div className="app-container">
        
      <div className="content">
        <div id="accueil"><Home /></div>
        <div id="about"><About /></div>
        <div id="formation"><Formation /></div>
        <div id="finance"><Finance /></div>
        <div id="finance"><Testimonials /></div>
      </div>
     
    </div>
    );
  }

export default Navigation;