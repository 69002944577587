import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import './topbar.css';
import { useTranslation } from 'react-i18next';

import frFlagImage from '../../static/images/flag_france.webp'; // Importez l'image du drapeau français
import enFlagImage from '../../static/images/flag_usa.png'; // Importez l'image du drapeau anglais

const TopBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar position="sticky" className={`topBarRoot ${isScrolled ? 'fixed' : ''}`}>
      <Toolbar style={{ minHeight: '20px' }} className='toolbar'> {/* Ajustez la hauteur du Toolbar */}
        <Box className="topBarLeftSection">
          <EmailIcon className="topBarIcon" />
          <Typography variant="body2">nyhoavikosiege@gmail.com</Typography>
          <PhoneIcon className="topBarIcon" />
          <Typography variant="body2">+261 34 52 577 16  |  +261 32 55 315 92</Typography>
       
        </Box>
        <Box className="topBarRightSection">
          <IconButton href="https://www.facebook.com/profile.php?id=100094211730897" target="_blank" className="topBarIcon">
            <FacebookIcon />
          </IconButton>
          <IconButton className="topBarIcon topBarLanguageIcon" onClick={() => changeLanguage('fr')}>
            <img src={frFlagImage} alt="French flag" className="flag-image" />
          </IconButton>
          <IconButton className="topBarIcon topBarLanguageIcon" onClick={() => changeLanguage('en')}>
            <img src={enFlagImage} alt="English flag" className="flag-image" />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
