// src/components/CandidatureProcess.js
import React from 'react';
import FloatingForm from './floatingForm';
import './candidatureProcess.css';
import { useTranslation } from 'react-i18next';


const CandidatureProcess = () => {
  const { t } = useTranslation();

  return (
    <div id="candidature" className="candidatureContainer">
      <div className="admissionsText">
      <p>{t('admission_button3')}</p>
        <p>{t('process_text1')}</p>
        <ul>
          <li>{t('process_text2')}</li>
          <li>{t('process_text3')}</li>
          <li>{t('process_text4')}</li>
        </ul>
        <p>{t('process_text5')}</p>
        <p>{t('process_text6')}</p>
      </div>
      <FloatingForm />
    </div>
  );
};

export default CandidatureProcess;
