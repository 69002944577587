// services/dbService.js

import dbData from '../db.json';
import frTranslations from '../locales/fr/translation.json';
import enTranslations from '../locales/en/translation.json';

export const getFormationsWithTranslations = (lang) => {
  const translations = lang === 'en' ? enTranslations : frTranslations;

  // Mappez les formations avec leurs traductions
  const formationsWithTranslations = dbData.formations.map((formation) => {
    // Vérifiez si la traduction existe pour cette formation
    const translatedFormation = translations.formations.find((item) => item.id === formation.id);
    
    return {
      id: formation.id,
      title: translatedFormation ? translatedFormation.title : formation.title,
      description: translatedFormation ? translatedFormation.description : formation.description,
      skills: translatedFormation ? translatedFormation.skills : formation.skills,
      training: translatedFormation ? translatedFormation.training : formation.training,
      image: formation.image, // Gardez l'image directement de dbData
    };
  });

  return formationsWithTranslations;
};
