// src/components/FormationDetails.js
import React from 'react';
import './admissionformation.css';
import { useTranslation } from 'react-i18next';


const FormationDetails = () => {
  const { t } = useTranslation();
  return (
    <div className="admissionsText_formation">
      <p>{t('admission_button2')}</p>
      <p>- {t('formation_text1')}</p>
      <p>- {t('formation_text2')}</p>      
      <p>- {t('formation_text3')}</p>
      <p>- {t('formation_text4')}</p>
      <p>- {t('formation_text5')}</p>
      <p>- {t('formation_text6')}</p>
      <p>- {t('formation_text7')}</p>
    </div>
  );
};

export default FormationDetails;
