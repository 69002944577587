import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './floatingForm.css';
import { useTranslation } from 'react-i18next';

const FloatingForm = () => {
  const { t } = useTranslation();

  const [cvFile, setCvFile] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [cinFile, setCinFile] = useState(null);
  const [cinvFile, setCinvFile] = useState(null);
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileChange = (e, setter) => {
    const file = e.target.files[0];
    setter(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Vérifier si tous les fichiers sont présents
    if (!cvFile || !photoFile || !cinFile || !cinvFile) {
      alert(t('select_all_files'));
      return;
    }

    handleOpen();
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendData = async () => {
    if (!email) {
      alert('Entrez votre email');
      return;
    }

    if (!isValidEmail(email)) {
      alert('Entrez une adresse email valide');
      return;
    }

    handleClose();

    const formData = new FormData();
    formData.append('email', email);
    if (cvFile) formData.append('cvFile', cvFile);
    if (photoFile) formData.append('photoFile', photoFile);
    if (cinFile) formData.append('cinFile', cinFile);
    if (cinvFile) formData.append('cinvFile', cinvFile);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert(t('upload_success'));
      } else {
        alert(t('upload_error'));
      }
    } catch (error) {
      alert(t('upload_exception') + error.message);
    }
  };

  return (
    <div className="centeredContent">
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" className="formTitle">
            {t('application_form')}
          </Typography>
          <form className="form" onSubmit={handleSubmit}>
            <Typography variant="body1" className="fieldName">
              {t('cv')}
            </Typography>
            <TextField 
              variant="outlined" 
              fullWidth 
              margin="normal" 
              type="file"
              onChange={(e) => handleFileChange(e, setCvFile)}
            />
            <Typography variant="body1" className="fieldName">
              {t('photo_id')}
            </Typography>
            <TextField 
              variant="outlined" 
              fullWidth 
              margin="normal" 
              type="file"
              onChange={(e) => handleFileChange(e, setPhotoFile)}
            />
            <Typography variant="body1" className="fieldName">
              {t('cin_front')}
            </Typography>
            <TextField 
              variant="outlined" 
              fullWidth 
              margin="normal" 
              type="file"
              onChange={(e) => handleFileChange(e, setCinFile)}
            />
            <Typography variant="body1" className="fieldName">
              {t('cin_back')}
            </Typography>
            <TextField 
              variant="outlined" 
              fullWidth 
              margin="normal" 
              type="file"
              onChange={(e) => handleFileChange(e, setCinvFile)}
            />
            <Button 
              variant="contained" 
              color="primary" 
              type="submit"
              className="submitButton"
            >
              {t('apply')}
            </Button>
          </form>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="customModal"
      >
        <div className="centeredModalContent">
          <Card className="modalCard">
            <CardContent>
              <Typography variant="h6" id="modal-modal-title">
                {t('enter_email')}
              </Typography>
              <TextField 
                label={t('email')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleSendData}
              >
                {t('send')}
              </Button>
            </CardContent>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default FloatingForm;
