import React from 'react';
import './footer.css'; // Importez votre fichier de style CSS pour le composant Footer
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook'; // Import de l'icône Facebook


const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer" style={{ backgroundColor: '#D19187' }}>
      <div className="footer-content">
        <div className="footer-info">
          <h3>My Future</h3>
          <p>{t('Adresse')}: logement 85 cité Ampefiloha A côté superette, Antananarivo, Madagascar</p>
          <p>{t('Télephone')}: +261 34 52 577 16 | +261 32 55 315 92</p>
          <p>{t('Email')}: nyhoavikosiege@gmail.com</p>
        </div>
        <div className="footer-social">
          <h3>{t('Follow_us')}:</h3>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
      <li>
        <a href="https://www.facebook.com/profile.php?id=100094211730897" target="_blank" rel="noopener noreferrer">
          <IconButton color="inherit">
            <FacebookIcon />
          </IconButton>
        </a>
      </li>
      {/* Ajoutez d'autres liens sociaux ici */}
    </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} {t('droit')} <a href="https://hoag-target.com/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "black"}}>HOAG Target.</a></p>
      </div>
    </footer>
  );
};

export default Footer;
