import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFormationsWithTranslations } from '../../services/dbService';
import './aboutFormation.css';
import i18n from '../../i18n';

const AboutFormation = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [formation, setFormation] = useState(null);

  useEffect(() => {
    // Fonction asynchrone pour récupérer la formation spécifique
    const fetchFormation = async () => {
      try {
        const formationsWithTranslations = getFormationsWithTranslations(i18n.language);
        const selectedFormation = formationsWithTranslations.find(form => form.id === parseInt(id, 10));

        if (selectedFormation) {
          setFormation(selectedFormation);
        } else {
          // Gérer le cas où la formation n'est pas trouvée
          setFormation(null);
        }
      } catch (error) {
        console.error('Error fetching formation:', error);
        setFormation(null); // Gérer l'erreur
      }
    };

    fetchFormation();
  }, [id, i18n.language]);

  if (!formation) {
    return <div>Loading...</div>;
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${formation.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)' /* Réglez l'opacité à 50% */
  };

  return (
    <div>
      <div className="aboutFormationContainer" style={backgroundImageStyle}>
        <div className="aboutFormationContent">
          <h1 className="aboutFormationTitle">{formation.title}</h1>
        </div>
      </div>

      <div className="aboutFormationText">
        <div className="jobDescription">
          <h2>{t('description_title')}</h2>
          <p className="descriptionParagraph"><span className="boldText">{formation.description}</span></p>
        </div>

        <div className="requiredSkills">
          <h2>{t('skills_title')}</h2>
          <ul>
            {formation.skills.map((skill, index) => (
              <li key={index} className="descriptionParagraph">{skill}</li>
            ))}
          </ul>
        </div>

        <div className="proposedTraining">
          <h2>{t('training_title')}</h2>
          <ul>
            {formation.training.map((item, index) => (
              <li key={index} className="descriptionParagraph">{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutFormation;
