import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu'; // Import de l'icône de menu
import { Link } from 'react-router-dom';
import './navbar.css';
import logo from '../../static/images/myfuture.jpeg';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // État pour gérer l'ouverture/fermeture du menu

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <AppBar position="sticky" className={`navbarRoot ${isScrolled ? 'fixed1' : ''}`}>
      <Toolbar className='appbar'>
        <Link to="/" className="navbarTitle" style={{textDecoration:'none'}}>
          <img src={logo} alt="Logo" className="navbarLogoImg" />
        </Link>
        {/* Afficher l'icône de menu uniquement sur les petits écrans */}
        <MenuIcon className="menuIcon" onClick={toggleMenu} />
        {/* Afficher les boutons du menu */}
        <div className={`menuItems ${isMenuOpen ? 'open' : ''}`}>
          <Button href="/" className="buttonnav" style={{ fontWeight: 'bold', color: 'white' }}>
            {t('accueil')}
          </Button>
          <Button href="/#about" className="buttonnav1" style={{ fontWeight: 'bold', color: 'white' }}>
          {t('Apropos')}
          </Button>
          <Button href="/#formation" className="buttonnav" style={{ fontWeight: 'bold', color: 'white' }}>
          {t('formation')}
          </Button>
          <Button href="/admission" className="buttonnav" style={{ fontWeight: 'bold', color: 'white' }}>
          {t('admission')}
          </Button>
          <Button href="/#finance" className="buttonnav" style={{ fontWeight: 'bold', color: 'white' }}>
          {t('tarification')}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
