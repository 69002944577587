// src/components/TestAdmission.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const TestAdmission = () => {
  const { t } = useTranslation();
  return (
    <div className="admissionsText">
      <p>{t('admission_button1')}</p>
      <p>- {t('candidature_text1')}</p>
      <p>- {t('candidature_text2')}</p>
      <p>- {t('candidature_text3')}</p>
      <p>- {t('candidature_text4')}</p>
    </div>
  );
};

export default TestAdmission;
