import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './formation.css';
import { useTranslation } from 'react-i18next';
import { getFormationsWithTranslations } from '../../services/dbService';
import i18n from '../../i18n';

const Formation = () => {
  const { t } = useTranslation();
  const [formations, setFormations] = useState([]);

  useEffect(() => {
    const fetchFormations = async () => {
      try {
        const formationsWithTranslations = getFormationsWithTranslations(i18n.language);
        console.log('Formations with translations:', formationsWithTranslations);
        setFormations(formationsWithTranslations);
      } catch (error) {
        console.error('Error fetching formations:', error);
        setFormations([]);
      }
    };

    fetchFormations(); // Charger les formations initialement
  }, [i18n.language]); // Ajouter i18n.language comme dépendance

  return (
    <div className="formation-container">
      <Typography variant="h5" className="formation-title" style={{maxWidth: '600px'}}>
        {t('formation_title')}
      </Typography>
      <div className="formation-grid">
        {formations.map(formation => (
          <Link to={`/formation/${formation.id}`} className="card-link" key={formation.id}>
            <Card className="formation-card">
              <CardMedia
                component="img"
                image={formation.image}
                alt={formation.title}
                className="formation-image"
              />
              <CardContent>
                <Typography variant="h6" className="formation-card-title">{t(formation.title)}</Typography>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Formation;
