// Testimonials.js
import React, { useState, useEffect } from 'react';
import './temoignage.css';
import { useTranslation } from 'react-i18next';

const testimonialsData = [
  { id: 1, text: "Excellent service!", image: "../../static/images/1.jpeg", author: "Hery Soloniaina" },
  { id: 2, text: "Les chocolats sont délicieux.", image: "../../static/images/2.jpeg", author: "Marcel" },
  { id: 3, text: "Je recommande fortement ce magasin.", image: "../../static/images/3.jpeg", author: "Hery" },
  { id: 4, text: "Je recommande fortement ce magasin.", image: "../../static/images/4.jpeg", author: "Tenaizy" },
  { id: 5, text: "Je recommande fortement ce magasin.", image: "../../static/images/5.jpeg", author: "Fabrice" },
  { id: 6, text: "Je recommande fortement ce magasin.", image: "../../static/images/6.jpeg", author: "Mano" },
  { id: 7, text: "Je recommande fortement ce magasin.", image: "../../static/images/7.jpeg", author: "Power" },
  { id: 8, text: "Je recommande fortement ce magasin.", image: "../../static/images/8.jpeg", author: "Finoana" },
  // { id: 9, text: "Je recommande fortement ce magasin.", image: "../../static/images/9.jpeg", author: "Soanala" },
  { id: 9, text: "Je recommande fortement ce magasin.", image: "../../static/images/10.jpeg", author: "Tantely" },
  { id: 10, text: "Je recommande fortement ce magasin.", image: "../../static/images/11.jpeg", author: "Fidèle" },
  { id: 11, text: "Je recommande fortement ce magasin.", image: "../../static/images/12.jpeg", author: "Mariah" },
  { id: 12, text: "Je recommande fortement ce magasin.", image: "../../static/images/13.jpeg", author: "Mendrika" },

  // Ajoute d'autres témoignages si nécessaire
];

const Testimonials = () => {
  const [current, setCurrent] = useState(0);
  const length = testimonialsData.length;
  const { t } = useTranslation();


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    }, 5000); // Change de témoignage toutes les 3 secondes
    return () => clearInterval(interval);
  }, [current, length]);


  const nextTestimonial = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevTestimonial = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <div className="testimonials-container">
      {testimonialsData.map((testimonial, index) => (
        <div
          key={testimonial.id}
          className={index === current ? 'testimonial active' : 'testimonial'}
        >
        <p className="testimonial-title"> {t('Des photos de nos diplomés à bord')}</p>
          <div className="testimonial-image-container">
            <img src={testimonial.image} alt={`Testimonial from ${testimonial.author}`} className="testimonial-image" />
            <p className="testimonial-author">{testimonial.author}</p>
            {/* <p className="testimonial-text">{testimonial.text}</p> */}
          </div>
        </div>
      ))}
      <button className="prev-button" onClick={prevTestimonial}>❮</button>
      <button className="next-button" onClick={nextTestimonial}>❯</button>
    </div>
  );
};

export default Testimonials;
