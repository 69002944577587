// src/pages/Admissions/Admission.js
import React, { useState } from 'react';
import './admission.css'; // Importez votre fichier de style CSS pour le composant Admissions
import TestAdmission from './testAdmission';
import FormationDetails from './formationDetails';
import CandidatureProcess from './candidatureProcess';
import { Link } from 'react-router-dom'; // Importer Link depuis react-router-dom
import { useTranslation } from 'react-i18next';

const Admission = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState('TestAdmission');

  const renderSection = () => {
    switch (activeSection) {
      case 'TestAdmission':
        return <TestAdmission />;
      case 'FormationDetails':
        return <FormationDetails />;
      case 'CandidatureProcess':
        return <CandidatureProcess />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="admissionsContainer">
        <div className="admissionsContent">
          <h1 className="admissionsTitle">{t('admission_title')}</h1>
        </div>
      </div>
      <div className="admissionsButtons">
        <button
          className={`admissionsButton ${activeSection === 'TestAdmission' ? 'active' : ''}`}
          onClick={() => setActiveSection('TestAdmission')}
        >
          {t('admission_button1')}
        </button>
        <button
          className={`admissionsButton ${activeSection === 'FormationDetails' ? 'active' : ''}`}
          onClick={() => setActiveSection('FormationDetails')}
        >
          {t('admission_button2')}
        </button>
        <button
          className={`admissionsButton ${activeSection === 'CandidatureProcess' ? 'active' : ''}`}
          onClick={() => setActiveSection('CandidatureProcess')}
        >
          {t('admission_button3')}
          </button>

      </div>
      {renderSection()}
      
    </div>
  );
};

export default Admission;
